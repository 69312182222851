import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/posts-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`中文 English。`}</p>
    <p>{`(Blog post content, components, etc.)
我是一些测试的中文。
asdas
你好`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.body {
  padding: 1px red solid;
}
`}</code></pre>
    <p>{`Here’s to the crazy ones.`}</p>
    <p>{`The misfits. The rebels. The troublemakers. The round pegs in the square holes. The ones who see things differently.
They’re not fond of rules. And they have no respect for the status quo. You can quote them, disagree with them, glorify or vilify them. About the only thing you can’t do is ignore them.
Here’s to the crazy ones.`}</p>
    <p>{`The misfits. The rebels. The troublemakers. The round pegs in the square holes. The ones who see things differently.
They’re not fond of rules. And they have no respect for the status quo. You can quote them, disagree with them, glorify or vilify them. About the only thing you can’t do is ignore them.
Because they change things. They push the human race forward. And while some may see them as the crazy ones, we see genius. Because the people who are crazy enough to think they can change the world, are the ones who do.`}</p>
    <h2>{`你好`}</h2>
    <h1></h1>
    <p>{`每当看到“GQ 实验室”的推送，我们都会对文章的脑洞感到震惊，小编到底要有多少生活经验、多少知识储备才能写出如此有料有趣的文章。`}</p>
    <p>{`其实，“GQ 实验室”的稿件几乎都是多人分工撰写的，每篇文章的协同编辑人数视情况而定，几人到十几人不等。在开始写作前，编辑们会先用石墨文档列一个内容提纲，然后分配任务，各自撰写领到的内容。就这样，编辑们在文档里“扮演”同一个人，通力协作，最终完成一篇篇 10W+爆款文章。`}</p>
    <p>{`由于石墨文档的内容是实时更新的，在撰写内容的时候，编辑们可以实时互相看到对方的进度，有时候，拖稿的编辑还会被同事开玩笑，直接用红色特大号字在文档里面 diss。`}</p>
    <p>{`W3C 一直期待能保证在推进 Web 技术，如 HTML, CSS, SVG 等的同时，满足世界各地的文字语言排版规则，以使 Web 页面和电子书等能被世界各地人民正确使用和阅读。
为了到达这个愿景，我们来自世界各地的专家一直在整理文字排版方面的需求，以及浏览器和电子书设备的支持与排版需求之间的差距。
其中一份旗舰性的文档是 Requirements for Japanese Text Layout, 《日文排版规范》。这份文档里面的信息被广泛地采用，并且文档的编写过程非常高效。在日本，这份文档是依托于一个特别任务组，以邮件列表和定期会议的方式完成的，此后被翻译成英文并且完成审阅。最后，这份文档发布了两种语言版本。
我们现在正在开展 Indic Layout Requirements，《印度语排版规范》 , Requirements for Hangul Text Layout and Typography，《朝鲜语排版规范》 and Requirements for Latin Text Layout and Pagination，《拉丁文排版与分页规范》。中文方面的排版规范也在进行中（链接后续补充），近期我们还在积极联系蒙古语、藏语和维吾尔语方面的专家，一起参与中文排版规范特别任务组并且发布相关文档。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      